import { theme } from 'antd'
import { DetailsPageLayout } from '../../../components'
import RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader from './components/RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader'
import RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel from './panels/RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel'

const { useToken } = theme

const RiskReliefRootCauseDiscoverySmartFunctionDetailsPage = () => {
  const { token } = useToken()
  const layoutBgColor = token.colorFillAlter

  return (
    <DetailsPageLayout $bgColor={layoutBgColor}>
      <RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader />
      <RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel />
    </DetailsPageLayout>
  )
}

export default RiskReliefRootCauseDiscoverySmartFunctionDetailsPage
