import { theme } from 'antd'
import { DetailsPageLayout } from '../../../components'
import RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader from './components/RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader'
import RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel from './panels/RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel'

const { useToken } = theme

const RiskReliefCAPAGenerationSmartFunctionDetailsPage = () => {
  const { token } = useToken()
  const layoutBgColor = token.colorFillAlter

  return (
    <DetailsPageLayout $bgColor={layoutBgColor}>
      <RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader />
      <RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel />
    </DetailsPageLayout>
  )
}

export default RiskReliefCAPAGenerationSmartFunctionDetailsPage
