import { theme } from 'antd'
import { DetailsPageLayout } from '../../../components'
import RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader from './components/RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader'
import RiskReliefDeviationClassificationSmartFunctionPanel from './panels/RiskReliefDeviationClassificationSmartFunctionPanel'

const { useToken } = theme

const RiskReliefDeviationClassificationSmartFunctionDetailsPage = () => {
  const { token } = useToken()
  const layoutBgColor = token.colorFillAlter

  return (
    <DetailsPageLayout $bgColor={layoutBgColor}>
      <RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader />
      <RiskReliefDeviationClassificationSmartFunctionPanel />
    </DetailsPageLayout>
  )
}

export default RiskReliefDeviationClassificationSmartFunctionDetailsPage
