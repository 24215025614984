import { BugOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  Badge,
  ExpandableTable,
  InclusionSwitch,
  TableBoldColumnContainer,
  SectionLoadingState,
} from '../../../../components'
import { BadgeColors } from '../../../../constants/constants'
import {
  useAlgorithmRunDetails,
  useRootCauseExclusions,
} from '../../../../hooks'
import { RiskReliefRootCauseDetails } from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const MainLabelContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`

const BadgeContainer = styled.div`
  margin-left: 8px;
`

const ExpandedSection = styled.div`
  display: flex;
  width: 100%;
  background: var(--color-bg-base);
  min-height: 48px;
  padding: 13px 4px;
  align-items: flex-start;
  flex: 1 0 0;
`

const ExpandedSectionRow = styled.div`
  display: flex;
  padding: 8px 0px;
  width: 100%;
`

const ExpandedSectionItemContainer = styled.div`
  padding: 0px 8px;
`

const PaddingBlock = styled.div`
  min-width: 48px;
  height: 100%;
`

const IdDetailsContainer = styled(ExpandedSectionItemContainer)`
  font-weight: 600;
  width: 40%;
`

const SummaryContainer = styled(ExpandedSectionItemContainer)`
  width: 40%;
`

const ToggleMainContainer = styled(ExpandedSectionItemContainer)`
  min-width: 20%;
  margin-left: -10px;
`

const ToggleDetailsContainer = styled(ExpandedSectionItemContainer)`
  width: 20%;
`

const SummarySubtitle = styled.div`
  color: ${(props) => props.theme.token.colorTextSecondary};
`

interface RiskReliefRootCauseDetailsExtended
  extends RiskReliefRootCauseDetails {
  included: boolean
}

interface RootCauseMainRow {
  tag: string
  key: string
  count: number
  total: number
  toggle: boolean
  details: RiskReliefRootCauseDetailsExtended[]
}

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel = () => {
  const { workspaceId, algorithmRunId } = useParams()
  const { selectedAlgorithmRun, loading } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })

  const rootCauseDiscovery = useMemo(
    () =>
      selectedAlgorithmRun?.nodeResults
        ? selectedAlgorithmRun?.nodeResults?.ROOT_CAUSE_DISCOVERY
        : undefined,
    [selectedAlgorithmRun]
  )

  const totalCauses = useMemo(() => {
    let totalCauses = 0
    for (const value of Object.values(rootCauseDiscovery ?? {})) {
      totalCauses += value.count
    }
    return totalCauses
  }, [rootCauseDiscovery])

  const formattedData = useMemo(() => {
    let rootCauses: RootCauseMainRow[] = []
    for (const [key, value] of Object.entries(rootCauseDiscovery ?? {})) {
      rootCauses.push({
        tag: key,
        key,
        count: value.count,
        total: totalCauses,
        toggle: true,
        details: [
          ...(value.details
            ? value.details.map((detail) => ({
                ...detail,
                included: true,
              }))
            : []),
        ],
      } as RootCauseMainRow)
    }

    return rootCauses.sort((a, b) => b.count - a.count)
  }, [rootCauseDiscovery, totalCauses])

  const {
    loadingIds,
    handleMasterOnSwitchClick,
    handleOnSwitchClick,
    isDocumentExcluded,
  } = useRootCauseExclusions({
    workspaceId,
    formattedData,
  })

  const expandedRowRender = (record: RootCauseMainRow) => (
    <ExpandedSection key={record.key}>
      {record.details &&
        record.details.map((row: RiskReliefRootCauseDetailsExtended) => (
          <ExpandedSectionRow key={`${row.documentId}-${record.tag}`}>
            <PaddingBlock />
            <IdDetailsContainer>{row.id}</IdDetailsContainer>
            <SummaryContainer>
              <SummarySubtitle>
                {t(
                  'smartFunctionDetailsPage.riskReliefRootCause.summarySubtitle'
                )}
              </SummarySubtitle>
              {row.rootCauseSummary}
            </SummaryContainer>
            <ToggleDetailsContainer>
              <InclusionSwitch
                checked={!isDocumentExcluded(row.documentId, record.tag)}
                onClick={() => handleOnSwitchClick(row.documentId, record.tag)}
                loading={loadingIds?.includes(row.documentId)}
              />
            </ToggleDetailsContainer>
          </ExpandedSectionRow>
        ))}
    </ExpandedSection>
  )

  const columns: ColumnsType<RootCauseMainRow> = [
    {
      title: t('smartFunctionDetailsPage.riskReliefRootCause.tableTitles.id'),
      dataIndex: 'id',
      key: 'id',
      width: '40%',
      render: (_: string, record: RootCauseMainRow) => {
        return {
          children: (
            <MainLabelContainer>
              {t(
                'smartFunctionDetailsPage.riskReliefRootCause.tableMainLabel.part1'
              )}
              <BadgeContainer>
                <Badge
                  color={BadgeColors.ROOT_CAUSE_DISCOVERY}
                  icon={<BugOutlined />}
                >
                  {record.tag ||
                    t(
                      'smartFunctionDetailsPage.riskReliefRootCause.noRootCause'
                    )}
                </Badge>
              </BadgeContainer>
              {t(
                'smartFunctionDetailsPage.riskReliefRootCause.tableMainLabel.part3',
                { count: record.count, total: record.total }
              )}
            </MainLabelContainer>
          ),
          props: { colSpan: 2 },
        }
      },
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefRootCause.tableTitles.summary'
      ),
      dataIndex: 'summary',
      key: 'summary',
      width: '40%',
      render: (_: string, record: RootCauseMainRow) => {
        return {
          props: { colSpan: 0 },
        }
      },
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefRootCause.tableTitles.inclusion'
      ),
      dataIndex: 'inclusion',
      key: 'inclusion',
      width: '20%',
      render: (_: string, record: RootCauseMainRow) => (
        <TableBoldColumnContainer>
          <ToggleMainContainer>
            <InclusionSwitch
              checked={
                !record.details.some((row) =>
                  isDocumentExcluded(row.documentId || '', record.tag)
                )
              }
              onClick={() =>
                handleMasterOnSwitchClick(record.details, record.tag)
              }
              loading={record.details.some((row) =>
                loadingIds.includes(row.documentId)
              )}
            />
          </ToggleMainContainer>
        </TableBoldColumnContainer>
      ),
    },
  ]

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <ExpandableTable
        dataSource={formattedData}
        columns={columns}
        expandedRowRender={expandedRowRender}
        tableClass="table-no-padding-expanded-section"
        rowClassName="smart-function-expandable-model"
        scroll={{ y: 'calc(100vh - 202px)' }}
      />
    </SmartFunctionListPanelLayout>
  )
}

export default RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel
