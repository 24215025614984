import { ApartmentOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Radio, theme } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  BackButton,
  DetailsPageLayout,
  IconTextContainer,
  PageContentDropdown,
  SubHeader,
} from '../../components'
import useWorkspaceDetails from '../../hooks/useWorkspaceDetails'
import { routePaths } from '../RootPage'
import DeviationStatusSwitch from './components/DeviationStatusSwitch'
import WorkspaceOverviewInfoDeviationPanel from './panels/WorkspaceOverviewInfoDeviationPanel'
import WorkspaceSmartFunctionPanel from './panels/WorkspaceSmartFunctionPanel'
import {
  workspacePanelTypes,
  WorkspaceTab,
  workSpaceTabs,
} from './WorkspaceDetailsPage'

const ListPanelLayout = styled.div`
  height: calc(100vh - 124px);
  border-radius: 16px 16px 0px 0px;
`

const FloatLeftContainer = styled.div`
  display: flex;
  margin-left: auto;
  order: 2;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
`
const { useToken } = theme

const WorkspaceRiskReliefDeviationDetailsPage = () => {
  const { workspaceId, deviationId, tab } = useParams()
  const navigate = useNavigate()
  const [fetchDone, setFetchDone] = useState(false)
  const { t } = useTranslation()

  const { refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const [selectedWorkspaceTab, setSelectedWorkspaceTab] = useState(
    tab as WorkspaceTab
  )

  useEffect(() => {
    if (!fetchDone) {
      refreshWorkspaceDetails()
      setFetchDone(true)
    }
  }, [fetchDone, refreshWorkspaceDetails])

  const handleChange = (value: WorkspaceTab) => {
    setSelectedWorkspaceTab(value)
    navigate(
      `${routePaths.WORKSPACE_DETAILS}/${workspaceId}/${workspacePanelTypes.DEVIATIONS}/${value}/${deviationId}`
    )
  }

  const handleWorkspaceDetailsPanelExport = () => {}

  const renderPanel = useCallback(() => {
    switch (selectedWorkspaceTab) {
      case workSpaceTabs.INFO:
        return <WorkspaceOverviewInfoDeviationPanel />
      case workSpaceTabs.SMART_FUNCTION:
        return <WorkspaceSmartFunctionPanel />
      default:
        return <WorkspaceOverviewInfoDeviationPanel />
    }
  }, [selectedWorkspaceTab])

  const { token } = useToken()

  const layoutBgColors = useMemo(() => {
    return {
      [workSpaceTabs.INFO]: token.colorFillAlter,
      [workSpaceTabs.SMART_FUNCTION]: token.colorBgLayout,
    }
  }, [token])

  return (
    <DetailsPageLayout $bgColor={layoutBgColors[selectedWorkspaceTab]}>
      <SubHeader>
        <BackButton
          type="default"
          url={`${routePaths.WORKSPACE_DETAILS}/${workspaceId}/${workspacePanelTypes.DEVIATIONS}/${workSpaceTabs.INFO}`}
        />

        <Radio.Group value={selectedWorkspaceTab} buttonStyle="solid">
          <Radio.Button
            value={workSpaceTabs.INFO}
            onClick={() => handleChange(workSpaceTabs.INFO as WorkspaceTab)}
          >
            <IconTextContainer>
              <InfoCircleOutlined />
              {t('workspaceDetailsPage.workspaceTabs.tabInfo')}
            </IconTextContainer>
          </Radio.Button>
          <Radio.Button
            value={workSpaceTabs.SMART_FUNCTION}
            onClick={() =>
              handleChange(workSpaceTabs.SMART_FUNCTION as WorkspaceTab)
            }
          >
            <IconTextContainer>
              <ApartmentOutlined />
              {t('workspaceDetailsPage.workspaceTabs.tabIntelligentSolution')}
            </IconTextContainer>
          </Radio.Button>
        </Radio.Group>
        <FloatLeftContainer>
          <DeviationStatusSwitch />
          <PageContentDropdown onExport={handleWorkspaceDetailsPanelExport} />
        </FloatLeftContainer>
      </SubHeader>
      <ListPanelLayout>{renderPanel()}</ListPanelLayout>
    </DetailsPageLayout>
  )
}

export default WorkspaceRiskReliefDeviationDetailsPage
