import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../../../components'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import {
  RiskReliefDeviationClassification,
  RiskReliefDeviationClassificationRunResult,
} from '../../../../store/reducers/workspaceReducer'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const SectionTableContainer = styled.div`
  background-color: ${({ theme }) => theme.token.colorBgLayout};
  border-radius: 16px;
  padding: 16px;
  margin-left: -16px;
  margin-right: -16px;
  @media print {
    height: auto;
  }
  height: calc(100vh - 224px);
`

interface DeviationClassificationRow extends RiskReliefDeviationClassification {
  key: string
}

const RiskReliefDeviationClassificationSmartFunctionPanel = () => {
  const { workspaceId, algorithmRunId } = useParams()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
  })

  const nodeResults = useMemo(
    () =>
      selectedAlgorithmRun?.nodeResults
        ? (selectedAlgorithmRun?.nodeResults
            ?.DEVIATION_CLASSIFICATION as RiskReliefDeviationClassificationRunResult)
        : undefined,
    [selectedAlgorithmRun]
  )

  const formattedData: DeviationClassificationRow[] = useMemo(
    () =>
      nodeResults && nodeResults.deviationClassification
        ? nodeResults.deviationClassification.map(
            (deviation: RiskReliefDeviationClassification) =>
              ({
                ...deviation,
                key: deviation.id,
              } as DeviationClassificationRow)
          )
        : [],
    [nodeResults]
  )

  const columns: ColumnsType<DeviationClassificationRow> = [
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefClassificationDeviation.table.idTitle'
      ),
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefClassificationDeviation.table.occurrenceTitle'
      ),
      dataIndex: 'occurrence',
      key: 'occurrence',
      width: '10%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text
            ? t(
                `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationValues.${text}`
              )
            : NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefClassificationDeviation.table.severityTitle'
      ),
      dataIndex: 'severity',
      key: 'severity',
      width: '10%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text
            ? t(
                `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationValues.${text}`
              )
            : NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefClassificationDeviation.table.detectabilityTitle'
      ),
      dataIndex: 'detectability',
      key: 'detectability',
      width: '10%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text
            ? t(
                `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationValues.${text}`
              )
            : NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefClassificationDeviation.table.riskTitle'
      ),
      dataIndex: 'risk',
      key: 'risk',
      width: '10%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text
            ? t(
                `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationRiskValues.${text}`
              )
            : NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefClassificationDeviation.table.identifiedRiskTitle'
      ),
      dataIndex: 'identifiedRisk',
      key: 'identifiedRisk',
      width: '50%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
  ]

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SectionTableContainer>
      <Table
        loading={loading}
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        scroll={{ y: 'calc(100vh - 202px)' }}
        rowClassName="page-list-table-row"
      />
    </SectionTableContainer>
  )
}

export default RiskReliefDeviationClassificationSmartFunctionPanel
